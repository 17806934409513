.navigation-drawer {
    display: none;
}

.navigation-drawer-item {
    display: flex;
    align-items: center;
    height: 56px;
    font-family: var(--typescale-label-large-font);
    font-weight: var(--typescale-label-large-weight);
    font-size: var(--typescale-label-large-size);
    line-height: var(--typescale-label-large-line-height);
    letter-spacing: var(--typescale-label-large-tracking);
    color: var(--color-on-surface);
    padding: 0 1.5rem;
    background: inherit;
    border-radius: var(--shape-corner-extra-large);
}

.navigation-drawer-item svg {
    margin: 0 0.75rem 0 -0.5rem;
}

.navigation-drawer-item-match {
    background: var(--color-secondary-container);
}

.navigation-drawer-item:hover {
    background: var(--color-on-surface-elevation-2);
}

.navigation-drawer-item:focus {
    background: var(--color-secondary-container);
}

.navigation-drawer-item:active {
    background: var(--color-on-surface-elevation-5);
}

@media only screen and (min-width: 1240px) {
    .navigation-drawer {
        display: block;
        padding: 80px 12px 12px 12px;
        height: 100vh;
        width: 100%;
        padding-top: 76px;
        overflow: auto;
    }
}


.navigation-drawer-item-disabled {
    /* background: var(--color-surface-variant); */
    color: var(--color-on-surface-variant);
    pointer-events: none;
    opacity: 0.4;
}