.planillas-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.planillas h3{
    margin-bottom: 1rem;
}


.planilla-card{
    background: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-medium);
    padding: 1rem;
    margin-bottom: 1rem;
}


.planillas-dates-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
}