.poliza-solicitud-ticket{
    background: var(--color-surface-elevation-1);
    padding: 1.5rem;
    margin: 1rem 0;
    position: relative;
    border-radius: var(--shape-corner-large);
}

.poliza-solicitud-button{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}