.create-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* width: 640px; */
    flex-wrap: wrap;
    /* background: red; */
}

.create-form-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.create-form-section-a {
    display: grid;
    grid-template-areas:
        "id id",
        "nombre apellidos",
        "email email";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 1rem;
    width: 100%;
}

.create-form-section-b {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;
    width: 100%;
}

.create-form-section-c {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}