.admin-roles-cards-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.admin-roles-card{
    background: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-medium);
    padding: 1.5rem 1.5rem 1rem 1.5rem;
}


.admin-roles-card-caption{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.admin-roles-table{
    margin: 1rem 0;
}

.admin-roles-form{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
}


.admin-avatar {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    cursor: copy;
}