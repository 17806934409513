.report-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.report-search-grid{
    display: grid;
    grid-template-columns: repeat(2, 240px);
    gap: 1rem;
}

.report-search-grid label{
    margin-bottom: 0.5rem;
}