.convenio-form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.convenio-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}


.convenio-card {
    background: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-large);
    padding: 1.5rem;
    margin: 1rem 0;
    position: relative;
}


.convenio-logo {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: var(--shape-corner-medium);
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
}

.convenio-logo-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96px;
    border-radius: var(--shape-corner-medium);
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    background: var(--color-surface-elevation-1);
    color: var(--color-on-surface);
    padding: 0.5rem;
}

.convenio-logo-upload:hover {
    background: var(--color-surface-elevation-2);
}


.convenio-delete-button {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
}