.post-card{
    background: var(--color-surface);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: var(--shape-corner-large);
    margin: 1rem 0;
    animation-name: postin;
    animation-duration: .3s;
}

@keyframes postin {
    from {
      opacity: 0;
      transform: translateY(16px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.post-card-tint{
    display: grid;
    grid-template-columns: 256px 1fr;
    /* grid-template-rows: 160px; */
    /* height: 100%; */
    /* width: inherit; */
    background: var(--color-surface-elevation-1);
    border-radius: inherit;
}

.post-card-img{
    border-radius: var(--shape-corner-large);
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.post-card-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    position: relative;
}


.post-upload{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--shape-corner-large);
  background: var(--color-surface-elevation-1);
  color: var(--color-on-surface);
}

.post-upload:hover{
  background: var(--color-surface-elevation-2);
}


.post-delete-button{
  position: absolute;
  right: .5rem;
  top: .5rem;
}