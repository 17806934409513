.caso-poliza-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.ins-dates-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
}