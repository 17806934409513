/* HOME */

.home-links-rail {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 156px);
  gap: 1rem;
  margin: 1rem 0;
}


.home-rail-item {
  height: 156px;
  width: 156px;
  border-radius: var(--shape-corner-large);
  background: var(--color-surface-elevation-2);
  color: var(--color-on-surface);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: .25rem;
  padding: 1rem;
  background: var(--color-surface-elevation-1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.3s;
}

.home-rail-item-disabled {
  background: var(--color-surface-variant);
  background: var(--color-surface-elevation-1);
  color: var(--color-on-surface-variant);
  pointer-events: none;
  opacity: 0.4;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
}

.home-rail-item:hover {
  background: var(--color-surface-elevation-2);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
}