.events-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.events-header h1 {
    margin: 0;
}

.events-date-selector {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.events-color-guide {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.event-table-container {
    overflow-x: auto;
    margin-top: 2rem;
}


.event-table th {
    padding: 1rem;
}

.event-table td {
    padding: 0;
}


/* PERSONAS */

.events-person-card {
    background: var(--color-surface-elevation-1);
    padding: 1.5rem;
    border-radius: 16px;
    display: flex;
    gap: 1rem;
    margin: 2rem 0;
    width: min-content;
}

.events-person-card-avatar {
    height: 56px;
    width: 56px;
    border-radius: 50%;
}


/* FAB */

.event-fab {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}



/* EVENT GRID */

.events-grid {
    display: grid;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 0.25rem;
}

.events-column {
    background: var(--color-surface-elevation-1);
    /* border-radius: var(--shape-corner-large); */
}

.events-column:hover {
    background: var(--color-surface-elevation-3);
    /* border-radius: var(--shape-corner-large); */
}


.events-column-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-surface-elevation-2);
    color: var(--color-on-primary-container);
    padding: 1rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.events-column-header-highlight {
    background: var(--color-primary);
    color: var(--color-on-primary);
}

.events-column:first-child {
    border-radius: 16px 0 0 16px;
}

.events-column:last-child {
    border-radius: 0 16px 16px 0;
}

.events-column-content {
    padding: 0.5rem;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


/* EVENT CARD */

.event-card {
    background: var(--color-secondary-container);
    color: var(--color-on-secondary-container);
    padding: 0.5rem;
    border-radius: var(--shape-corner-small);
    animation-name: eventin;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    cursor: pointer;
    transition: 0.3s ease-out box-shadow;
}

.event-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

@keyframes eventin {
    from {
        opacity: 0;
        scale: 0.75;
    }

    to {
        opacity: 1;
        scale: 1;
    }
}

.event-card-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* opacity: 0.6; */
}

.event-card-mine {
    background: var(--color-primary);
    color: var(--color-on-primary);

}

.event-card-mine p {
    /* color: red; */
    /* font-weight: 700; */
}

.event-card-in-progress {
    background: var(--color-tertiary-container);
    color: var(--color-on-tertiary-container);
}

.event-card-creator {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0.5rem;
    opacity: 0.6;

}

.event-card-creator p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.event-card-avatar {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0.25rem;
    animation-name: eventin;
    animation-duration: .3s;
    animation-timing-function: ease-out;
}

/* IMG */

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: var(--color-inverse-surface);
    color: var(--color-inverse-on-surface);
    text-align: center;
    border-radius: var(--shape-corner-small);
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: 40px;
    left: 50%;
    margin-left: -90px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-inverse-surface) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* EVENT CREATE DIALOG */


.event-create-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.event-create-participant-chip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: var(--shape-corner-small);
    background: var(--color-surface-elevation-2);
    margin-top: 1rem;
}

.event-create-avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0.25rem;
    animation-name: eventin;
    animation-duration: .3s;
    animation-timing-function: ease-out;
}