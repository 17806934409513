.soon_row {
    background-color: var(--color-tertiary-container);
    color: var(--color-on-tertiary-container);
}

.soon_row:hover {
    background-color: var(--color-tertiary-container);
    color: var(--color-on-tertiary-container);
}


.avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
}


/* dialog */

.dialog {
    margin: auto;
    padding: 0;
    border: 0;
    z-index: 20000;
}

.dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
    animation-fill-mode: forwards;
    padding: 0;
}

/* @media only screen and (min-width: 600px) {
    .dialog::backdrop {
        backdrop-filter: blur(16px) saturate(150%);
    }
} */

.dialog[open] {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: calc((100%) - 3em);
    max-height: calc((100%) - 3rem);

    animation-name: dialogIn;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
    animation-fill-mode: forwards;
    background: none;
}

.dialog:not([open]) {
    pointer-events: none;
    opacity: 0;
}

.container {
    background-color: var(--color-surface);
    padding: 1.5rem;
    border-radius: 28px;
    max-width: 480px;
    width: 480px;
    border: 1px solid var(--color-outline-variant);
}

.close[open] {
    animation-name: dialogOut;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
    animation-fill-mode: forwards;
}

.close::backdrop {
    animation-name: fadeOut;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes dialogIn {
    from {
        opacity: 0;
        transform: scale(115%);
    }

    to {
        opacity: 1;
        transform: scale(100%);
    }
}

@keyframes dialogOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* INTERNAL */

.x_button {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.badge {
    background-color: var(--color-error);
    color: var(--color-on-error);
    height: 24px;
    width: 24px;
    border-radius: 12px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 700;
    /* position: absolute; */
    /* right: 0.5rem; */
    /* top: 1rem; */
}

.icon_badge {
    background-color: var(--color-error);
    color: var(--color-on-error);
    height: 12px;
    width: 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 700;
    position: absolute;
    left: 0.75rem;
    top: 1.5rem;
}