.layout {
    /* background-color: red; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
}

.main {
    /* background-color: green; */
    /* margin-right: 360px; */
}

.main h4 {
    margin: 1rem 0;
}


.form_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.chat {
    /* background-color: blue; */
    position: sticky;
    right: 0;
    top: 6rem;
    /* bottom: 0; */
    height: calc(100vh - 7rem);
    /* width: 360px; */
    /* max-height: inherit; */
    /* padding-bottom: 1.5rem; */

}

.icon_badge {
    background-color: var(--color-error);
    color: var(--color-on-error);
    height: 12px;
    width: 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 700;
    margin-right: 0.5rem;
}