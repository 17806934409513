.signup{
    width: 360px;
    /* height: 400px; */
    background: var(--color-surface-elevation-1);
    border-radius: var(--shape-corner-medium);
    margin: 4rem auto;
    padding: 2rem;
}


.signup-form{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
}


.signup-card-tint{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.signup-card-tint form{
    display: flex;
    flex-direction: column;
}