.profile h3{
  margin: 1rem 0;
}

.profile h4{
  margin: 1rem 0;
}

.profile-header{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}


.perfil-afiliacion-header{
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.profile-form-section-b {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 1rem;
  width: 100%;
}


.create-form{
  /* background: red; */
  width: 100%;
  margin: auto;
}

.profile-form-persona{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.create-form-section-c {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-save-button{
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  color: var(--color-primary);
  z-index: 50000;
}

/* BENEFICIARIOS */

.perfil-beneficiarios-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 1rem 0;
}

.perfil-beneficiarios-list{
  /* display: grid; */
  display: flex;
  flex-direction: column;
  /* grid-template-rows: repeat(auto-fill, auto); */
  gap: 1rem;
}

.perfil-beneficiario-card{
  background: var(--color-surface-elevation-1);
  padding: 1rem;
  position: relative;
  border-radius: var(--shape-corner-medium);
}

.beneficiario-delete-button{
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: var(--color-error);
}