.casos-table-container{
    overflow-x: auto;
    margin: 1rem 0;
}


.casos-tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 1rem; */
    background: var(--color-surface-elevation-1);
    /* padding: 1rem; */
    border-radius: 32px;
    margin-bottom: 1rem;
}


.casos-tabs button{
    width: 100%;
    height: 56px;
}