.evento-header{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.evento-card {
    background: var(--color-surface-elevation-1);
    background: var(--color-secondary-container);
    /* background: var(--color-surface-variant); */
    color: var(--color-on-secondary-container);
    /* color: var(--color-on-surface-variant); */
    border-radius: var(--shape-corner-large);
    padding: 2rem;
    position: relative;
}

.evento-titulo {
    padding-right: 4rem;
}


.evento-fecha {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}


.evento-duracion {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}

.evento-inicio-fin-container {
    display: flex;
    justify-content: space-between;
}

.evento-locacion {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.evento-participantes-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.evento-participante {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.evento-participante-avatar {
    height: 56px;
    width: 56px;
    object-fit: cover;
    border-radius: 50%;
}