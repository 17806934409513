.scrim {
  background-color: rgba(0, 0, 0, 0.0);
  height: 100vh;
  height: var(--app-height);
  width: 100vw;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 150;
  opacity: 0;
  visibility: hidden;
  transition: visibility .3s, opacity .3s, background-color .3s, height .3s;
}

.scrim-visible {
  background-color: rgba(0, 0, 0, 0.4);
  background: var(--color-on-surface-elevation-3);
  opacity: 1;
  visibility: visible;
}


.modal-navigation-drawer {
  height: 100vh;
  height: var(--app-height);
  width: calc(100vw - 56px);
  padding: 16px 12px 0 12px;
  position: fixed;
  left: -360px;
  top: 0;
  overflow-y: auto;
  border-radius: var(--shape-corner-large-end);
  background: var(--color-surface);
  transition: left .3s ease-in-out, height .3s;
}


.modal-navigation-drawer h5{
  margin-bottom: 1rem;
}

.modal-navigation-drawer-opened {
  left: 0px;
}

@media only screen and (min-width: 600px) {

  .modal-navigation-drawer {
    width: 360px;
  }

}


/* ACCOUNT */
.drawer-account-card {
  display: flex;
  gap: 1rem;
  margin: 0 0 1rem 0;
  background: var(--color-surface-elevation-1);
  padding: 1rem;
  border-radius: var(--shape-corner-small);
  position: relative;
}

.drawer-account-card img{
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.drawer-account-signout{
  position: absolute;
  right: .25rem;
  top: .25rem;
  z-index: 20000;
}